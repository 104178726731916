// Step 1: Import your component
import * as React from 'react'
import Layout from "../components/layout"
import { StaticImage } from 'gatsby-plugin-image'


// Step 2: Define your component
const AboutPage = () => (
    <Layout>
      <div className='container'style={{paddingTop:'4rem', paddingBottom:'2rem' }}>

      <div className='split'>

      <div style={{margin: 'auto auto'}}>
      <h1>I bring value to digital products informed by the dimensions of design, data, risk and customer centricity.</h1> 
      </div>


      <div className='imagehome'>
      <StaticImage
        alt="connecting the dots"
        src="../images/Illustration2.png"
      />
      </div> 

      </div>
      </div>

  <div className='container' style={{backgroundColor:'#292929', paddingTop:'3rem', paddingBottom:'2rem'}}>

    <div style={{paddingTop: '1.5rem', justifyContent:"center"}} >
    <h3 style={{color:'#fff'}} >“Believer in the Design of the Universe. Skeptic of the design of everyday things. Living by better design”</h3>
    <h5 style={{color:'#A1A1A1', paddingTop:'0.5rem'}}> - My Life's Philosophy .</h5>
    </div>

    <div class='split'>

    <div>
    <h3 style={{color:'#fff'}} >About</h3> 
    <p style={{color:'#fff', padding:'1rem 0'}}> Hi, I'm Shweta. A continuous learner and connector of dots. I have been strategizing, planning and executing projects and products across - design, data, risk and customer centricity. </p> 
    <p style={{color:'#fff', padding:'1rem 0'}}> My decade + of work has summarily been about researching, auditing, analyzing to bridge understanding across customers, business, design, and engineering. I have worn many hats as a consultant to a company of one helping improve product experiences, functional business operations, and customer management and communication.</p>
    <p style={{color:'#fff', padding:'1rem 0'}}> Within the <strong>data</strong> space, I stepped into roles requiring Risk/fraud mitigation and compliance adherence <strong>to</strong> Managing customer communications for 10M users. And within the <strong>design</strong> space, the multiple facets I worked on range from engineering design (CAD) <strong>to</strong> User Experience and Product Design. I have recently started building out web products with <strong>code</strong>, including this one.</p>
    <p style={{color:'#fff', padding:'1rem 0'}}> My current work involves exploring the fundamental question - Are we building the right thing? For the right people? Within the context?</p>.

    </div>

    <div >
    <h3 style={{color:'#fff', padding:'2rem 0'}}>Certifications & Continuous Learning</h3> 
    <p style={{color:'#fff',padding:'0.5rem 0'}} > AWS Cloud Practitioner <span style={{paddingTop:'0.25rem', color:'#A1A1A1', fontSize:'0.9rem'}}> AWS Certifications.</span> </p>    
    <p style={{color:'#fff',padding:'0 0'}} > Product Analytics Certification (PAC)TM <span style={{paddingTop:'0.25rem', color:'#A1A1A1', fontSize:'0.9rem'}}>Product School</span></p>
    <p style={{color:'#fff',padding:'0 0'}} > Data Product Manager <span style={{paddingTop:'0.25rem', color:'#A1A1A1', fontSize:'0.9rem'}}>Udacity Nanodegree </span> </p>
    <p style={{color:'#fff',padding:'0.5rem 0'}} > Design Thinking, Neuroscience <span style={{paddingTop:'0.25rem', color:'#A1A1A1', fontSize:'0.9rem'}}>MOOC's and Books</span> </p>

    <h3 style={{color:'#fff', padding:'2rem 0'}}>Education</h3> 
    
    <p style={{color:'#fff',padding:'0.5rem 0'}} >M.B.A. NMIMS University, Decision Sciences and Operations (2009)</p>
    <p style={{color:'#fff'}} > B.E. Osmania University, CBIT, Mechanical Engineering (2005) </p>
    <h3 style={{color:'#fff', padding:'2rem 0'}}> Other stuff </h3> 
    <p style={{color:'#fff',padding:'0 0'}} >  Creating and connecting our dots is a constant endeavor I undertake as a Mom. If you want to take a peek at the little brave things my two kids teach me about - head over to ig@cocoonscribbles.</p>
    </div>

    </div>
    
    </div>

    </Layout>
  )


// Step 3: Export your component My career path is a living example of things I wanted to learn and problems and challenges I wanted to solve. 
export default AboutPage